import sortBy from 'lodash/sortBy';
import queryString from 'query-string';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token';
import type { ApplicationEdition } from '@atlassian/jira-shared-types';
import { transitionType, SCOPE_GLOBAL, STATUS_CATEGORY_WEIGHTS } from './constants';
import type {
	AvailableRule,
	Rule,
	RuleBlueprint,
	Status,
	Transition,
	WorkflowScope,
} from './types';

export const isRuleTypeConstrainedByProductOrScope = (
	ruleType: RuleBlueprint,
	projectType?: ProjectType,
	appEdition?: ApplicationEdition,
	scope?: WorkflowScope,
): boolean => {
	if (scope === SCOPE_GLOBAL) {
		return !ruleType.constraints.scope.includes(scope);
	}

	// @ts-expect-error - TS2345 - Argument of type '"PROJECT" | undefined' is not assignable to parameter of type 'WorkflowScope'.
	if (!ruleType.constraints.scope.includes(scope)) {
		return true;
	}
	const compatibleProductConstraint = ruleType.constraints.compatibleProducts.find(
		(product) => product.type === projectType,
	);

	// If compatibleProductConstraint is not found, the rule type will be constrained
	return compatibleProductConstraint
		? // @ts-expect-error - TS2345 - Argument of type 'ApplicationEdition | undefined' is not assignable to parameter of type 'ApplicationEdition'.
			!compatibleProductConstraint.editions.includes(appEdition)
		: true;
};

/** @deprecated remove with hix-2476_integrate_available_rules_api */
export const isRuleTypeConstrainedByTransition = (
	ruleType: RuleBlueprint,
	transition?: Transition,
): boolean =>
	transition
		? !ruleType.constraints.availableForInitial && transition.type === transitionType.INITIAL
		: false;

/** @deprecated remove with hix-2476_integrate_available_rules_api */
export const isRuleTypeConstrainedByAnotherRule = (
	ruleType: RuleBlueprint,
	rules: Rule[],
): boolean =>
	rules.some((rule) =>
		ruleType.constraints.incompatibleRules.some(
			(constraint) => rule.ruleKey === constraint.qualifiedKey,
		),
	);

export const orderStatusesByCategoryAndName = (statuses: Status[]): Status[] =>
	sortBy(statuses, [(status) => STATUS_CATEGORY_WEIGHTS[status.statusCategory], 'name']);

export const getLegacyEditorLink = (workflowName: string) =>
	`/secure/admin/workflows/WorkflowDesigner.jspa?workflowMode=live&wfName=${encodeURIComponent(
		workflowName,
	)}`;

export const getNewEditorLink = (workflowId: string) =>
	`/jira/settings/issues/workflows/${workflowId}`;

export const openLegacyEditor = (workflowName: string) => {
	const url = getLegacyEditorLink(workflowName);
	window.location.href = url;
};

export const buildEditWorkflowJSPUrl = (workflowName: string) =>
	`/secure/admin/workflows/EditWorkflowDispatcher.jspa?${queryString.stringify({
		atl_token: getXsrfToken(),
		wfName: workflowName,
	})}`;

export const conditionallyNarrowType = <A, B = unknown>(
	value: A | B,
	condition: boolean,
): value is A => condition;

export const findRuleDefinitionForConfiguredRule = (
	rule: Rule,
	availableRules: AvailableRule[],
): AvailableRule | undefined => {
	const ruleKey = rule.ruleKey;

	if (ruleKey.startsWith('connect')) {
		const appKey = rule.config.appKey;

		return availableRules.find(
			(definition) =>
				definition.variety === 'connect' &&
				definition.key === ruleKey &&
				definition.appKey === appKey,
		);
	}

	return availableRules.find((definition) => definition.key === ruleKey);
};
